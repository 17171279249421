<template>
<div class="mainContainer" @click="viewAppointmentDetails">
    <div style="display: flex; justify-content: space-between; align-items: center; width: 100%; border-bottom: 1px solid #e0e0e0; padding-bottom: 12px;">
            <div class="doctorNameAndLogo">
                <div class="doctorProfile">
                    <img :src="appointmentInfo.doctorLogoURL" style="border-radius: 50%; object-fit: cover; width: 100%; height: auto;"/>
                </div>
                <div class="doctorName">
                    <div style="font-weight: 500; font-size: 14px;">{{ appointmentInfo.doctorName }}</div>
                    <div style="color: #1467BF; font-weight: 400; font-size: 14px;" class="specialtyBox" >{{ appointmentInfo.doctorSpecialty }}</div>
                </div>
            </div>
            <div style="display: flex; flex-direction: column; align-items: flex-end; gap: 4px;">
                <div class="tokenNumber" :style="calculateStyle()">{{ appointmentInfo.tokenNumber }}</div>
                <div style="font-size: 12px; color: #828282; font-weight: 400;">{{$t("Customer.Appointments.token_no")}}</div>
            </div>
    </div>
    <div style="display: flex; flex-direction: column; gap: 16px;">
        <div style="display: flex; justify-content: space-between;">
            <div style="display: flex; gap: 8px;">
                <img src="https://s3iconimages.mymedicine.com.mm/clock.svg" alt="" style="width: 25px; height: 25px;">
                <div>{{ appointmentInfo.slotTime }}</div>
            </div>
            <div style="display: flex; gap: 8px;">
                <img src="https://s3iconimages.mymedicine.com.mm/calendar.svg" alt="" style="width: 25px; height: 25px;">
                <div>{{ appointmentInfo.slotDate }}</div>
            </div>
        </div>
        <div style="">
            <div class="hospitalNameAndLogo">
                <div class="hospitalLogo">
                    <img :src="appointmentInfo.hospitalLogo" style="max-width: 100%; max-height: 100%; object-fit: contain; border-radius: 12px;"/>
                </div>
                <div class="hospitalName">
                    <div style="font-weight: 500; font-size: 14px;">{{ appointmentInfo.hospitalName }}</div>
                    <div style="color: #4f4f4f; font-size: 12px; font-weight: 400;">{{ appointmentInfo.hospitalAddress }}</div>
                </div>
        </div>
        </div>
    </div>
    <!-- <div v-if="completedFlag" style="display: flex; flex-direction: column; align-items: flex-start; border-top: 1px solid #e0e0e0; padding-top: 16px;">
        <div style="font-size: 14px; font-weight: 400;">Rate overall experience</div>
        <div style="display: flex; gap: 10px; padding-top: 5px;">
            <div v-for="i in 5" :key="i" >
            <img src="https://s3iconimages.mymedicine.com.mm/whiteHeart.svg" style="width: 25px; height: 25px;">
        </div>
        </div>
    </div> -->
</div>
</template>    
<script>



export default {
    name: 'singleAppointmentComponent',
    props: ['completedFlag', 'appointmentInfo'],
    data: function() {
        return {
            // completedFlag: true,
        };
    },
    computed: {
        textPreview() {
            return this.fullDescription.slice(0, 100) + '...';
        },
        specialtyList () {
            return this.$store.state.OPDConfigCommonState.opdSpec;        
        },
    },
    async mounted() {
    },
    methods: {
        getSpecialtyName(item) {
            console.log(item);
            let findItem = this.specialtyList.find((x) => (x.id == item));
            console.log(findItem, item, this.specialtyList);
            if (findItem) {
                return findItem.name;
            } else {
                return ''
            }
        },
        viewAppointmentDetails () {
            this.$router.push({
                name: 'particularOPDAppointment',
                params: {
                    'bookingID': this.appointmentInfo.bookingID
                }
            })
        },
        calculateStyle() {
            if(this.completedFlag) {
                return 'color: #bdbdbd;'
            } else {
                return 'color: #1467BF;'
            }
        },
        goToOPDPage() {
            this.$router.push({
                name: 'OPDHome'
            })
        },
        toggleHospitalDetails() {
            this.viewDetailsModel = !this.viewDetailsModel;
        },
        closeBottomSheet() {
            this.viewDetailsModel = false;
        },
        descriptionPreview() {

        }
    },
};

</script>
<style  scoped>
.mainContainer {
    padding: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    background: white;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    cursor: pointer;
}

.doctorName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
}
.doctorProfile {
    border-radius: 50%;
    max-height: 56px;
    max-width: 56px;
    height: 56px;
    width: 56px;
    border: 1px solid #e0e0e0;
}
img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.doctorNameAndLogo {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-grow: 1;
}
.specialtyBox {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #E4F5FF;
    padding: 3px 8px 3px 8px;
}
.tokenNumber {
    height: 32px;
    width: 32px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    /* color: #bdbdbd; */
}
.hospitalName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}
.hospitalLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 56px;
    max-width: 56px;
    min-height: 56px;
    min-width: 56px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
}
.hospitalNameAndLogo {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    font-size: 14px !important;
}
</style>
